import { useEffect, useMemo, useRef } from 'react';
import { usePrevious } from 'react-use';

import {
  BroadcastIndicator,
  BroadcastToggle,
  useOneTimeAutomaticBroadcastToggleOff,
} from '../../../../components/Broadcast';
import { LobbyBroadcastBundle } from '../../../../components/Broadcast/Utilities';
import { EnterExitTailwindTransition } from '../../../../components/common/TailwindTransition';
import { LayoutAnchor } from '../../../../components/LayoutAnchors/LayoutAnchors';
import {
  useParticipantFlags,
  useParticipantsByClientIds,
} from '../../../../components/Player';
import { useIsStreamSessionAlive } from '../../../../components/Session';
import { useSoundEffect } from '../../../../components/SFX';
import {
  useIsTeamCaptainScribe,
  useTeamWithStaff,
} from '../../../../components/TeamAPI/TeamV1';
import { useTeamMembers } from '../../../../components/TeamAPI/TeamV1';
import { useJoinTeam } from '../../../../components/TeamAPI/TeamV1';
import { useMyClientId } from '../../../../components/Venue/VenuePlaygroundProvider';
import { useVenueId } from '../../../../components/Venue/VenueProvider';
import {
  useJoinRTCService,
  usePublishStream,
  useRTCService,
  useTriggerWebRTCJoinFailedModal,
} from '../../../../components/WebRTC';
import { type TaskQueue } from '../../../../hooks/useTaskQueue';
import { useVenueMode } from '../../../../hooks/useVenueMode';
import { safeWindowReload } from '../../../../logger/logger';
import {
  type IMediaDeviceRTCService,
  WebRTCUtils,
} from '../../../../services/webrtc';
import { type TeamId } from '../../../../types';
import { type Participant } from '../../../../types/user';
import { VenueMode } from '../../../../types/venue';
import { useSubscribeAudienceRTCEvents } from '../Common/useSubscribeAudienceRTCEvents';
import { LocalStreamView } from '../LocalStreamView';
import { MiniPointsBadge } from '../PointsBadge';
import { useRemoteStreamStateAPI } from '../RemoteStreamStateProvider';
import { RemoteStreamView } from '../RemoteStreamView';
import { RetryJoinTeam } from '../RetryJoinTeam';
import { Header } from './Header';

interface Props {
  teamId: TeamId;
  me: Participant;
  miniMode: boolean;
  venueMode: VenueMode;
  taskQueue: TaskQueue;
}

interface TeamRowProps {
  teamId: TeamId;
  members: Participant[];
  rtcService: IMediaDeviceRTCService;
  joinTaskQueue: TaskQueue;
  miniMode: boolean;
}

const getVideoContainerId = (p: Participant): string => {
  return `tm-${p.teamId}-${p.clientId}`;
};

const getShapeClassNameByNumOfTeamMembers = (
  miniMode: boolean,
  num: number
): string => {
  const classNames = [
    'filter box-content drop-shadow-lp-team-stream border-transparent border-2 duration-700 transition-size',
  ];
  if (miniMode) {
    classNames.push('w-10 h-10 rounded-lg');
  } else {
    classNames.push('mx-0.5 group-1-hover:border-primary');
    if (num <= 4) {
      classNames.push(
        'w-30 h-30 2xl:w-40 2xl:h-40 rounded-5.5xl 2xl:rounded-6.25xl'
      );
    } else if (num <= 6) {
      classNames.push('w-25 h-25 2xl:w-35 2xl:h-35 rounded-4xl rounded-5.5xl');
    } else {
      classNames.push(
        'w-20 h-20 2xl:w-25 2xl:h-25 rounded-3.5xl 2xl:rounded-4xl'
      );
    }
  }
  return classNames.join(' ');
};

function TeamView({
  teamId,
  members,
  rtcService,
  joinTaskQueue,
  miniMode,
}: TeamRowProps): JSX.Element | null {
  const venueId = useVenueId();
  const myClientId = useMyClientId();
  const flags = useParticipantFlags(myClientId);
  const rtcChannel = useMemo(
    () => WebRTCUtils.ChannelFor('team', venueId, teamId),
    [teamId, venueId]
  );
  const rtcJoinStatus = rtcService.joinStatus();

  // Since we use the same RTCService instance for team streams, it needs to
  // leave and join the channel after switching teams. However leave and join
  // are both async operation, when teamId changes, the RTCService instance
  // still holds the old join status while leaving the old channel. If we
  // don't prevent code from calling APIs such as play, publish, which would
  // cause unexpected errors or bugs.
  const isRTCServiceSynced = rtcJoinStatus
    ? rtcJoinStatus.channel.includes(teamId)
    : false;

  const { me, others } = useMemo(() => {
    const result: { me: Participant | null; others: Participant[] } = {
      me: null,
      others: [],
    };
    members.forEach((m) => {
      if (m.clientId === myClientId) {
        result.me = m;
      } else {
        result.others.push(m);
      }
    });
    return result;
  }, [members, myClientId]);

  const shapeClassName = getShapeClassNameByNumOfTeamMembers(
    miniMode,
    members.length
  );
  const joined = useJoinRTCService(
    rtcService,
    rtcChannel,
    'host',
    joinTaskQueue,
    {
      handleFailure: useTriggerWebRTCJoinFailedModal(),
    }
  );

  const { enqueueSynchronize } = usePublishStream(rtcService);
  enqueueSynchronize(joined);
  useSubscribeAudienceRTCEvents(rtcService);

  useEffect(() => {
    if (members.length <= 4) {
      rtcService.setEncoderConfiguration('240p');
    } else {
      rtcService.setEncoderConfiguration('120p');
    }
  }, [members.length, rtcService]);

  const api = useRemoteStreamStateAPI();

  useEffect(() => {
    if (!joined) return;
    return () => {
      rtcService.stopAll();
      api.clearStreamState();
    };
  }, [api, joined, rtcService]);

  if (!me || !flags) return null;

  return (
    <div
      className={`flex mt-3 relative pointer-events-on ${
        miniMode ? 'team-container' : 'rounded-5xl'
      } duration-700 transition-size`}
    >
      {!miniMode ? null : (
        <div className='absolute top-0 right-full h-full pr-2.5 flex items-center justify-center'>
          <MiniPointsBadge />
        </div>
      )}
      <LocalStreamView
        className={shapeClassName}
        id={getVideoContainerId(me)}
        rtcService={rtcService}
        clientId={me.clientId}
        isRTCServiceSynced={isRTCServiceSynced}
        teamMemberCount={members.length}
        miniMode={miniMode}
        widthPx={undefined}
        heightPx={undefined}
        borderRadiusPx={undefined}
      />
      {others.map((m) => {
        return (
          <RemoteStreamView
            key={`${m.teamId}#${m.clientId}`}
            id={getVideoContainerId(m)}
            className={shapeClassName}
            targetMemberId={m.clientId}
            currentMemberId={me.clientId}
            rtcService={rtcService}
            isRTCServiceSynced={isRTCServiceSynced}
            teamMemberCount={members.length}
            miniMode={miniMode}
            strategy={{
              video: 'auto',
              audio: 'auto',
            }}
            widthPx={undefined}
            heightPx={undefined}
            borderRadiusPx={undefined}
          />
        );
      })}
      {!miniMode ? null : (
        <EnterExitTailwindTransition
          initialClasses={'-translate-x-full opacity-0'}
          enteredClasses={`translate-x-0 opacity-100`}
        >
          {(ref, initial) => (
            <div
              ref={ref}
              className={`
                absolute top-0 left-full h-full pl-2.5
                flex items-center justify-center
                pointer-events-on
                transition-all transform duration-500 ${initial}`}
            >
              <BroadcastToggle />
            </div>
          )}
        </EnterExitTailwindTransition>
      )}
    </div>
  );
}

function Container({
  teamId,
  me,
  miniMode,
  venueMode,
  taskQueue,
}: Props): JSX.Element | null {
  const isSessionAlive = useIsStreamSessionAlive();

  useOneTimeAutomaticBroadcastToggleOff(() => isSessionAlive, isSessionAlive);

  const team = useTeamWithStaff(teamId);
  const teamMembers = useTeamMembers(teamId, true, me.clientId);
  const memberIds = useMemo(
    () => teamMembers?.map((m) => m.id) ?? [],
    [teamMembers]
  );
  const teamParticipants = useParticipantsByClientIds(memberIds);
  const amIInTheTeam = memberIds.includes(me.clientId);
  const joinTeam = useJoinTeam();

  const participants = teamParticipants;

  const numOfMembers = teamParticipants.length;
  const prevNumOfMembers = usePrevious<number>(numOfMembers);
  const isLeader = useIsTeamCaptainScribe(teamId, me.clientId);
  const { play: joinTeamSFX } = useSoundEffect('joinTeam');
  const { play: leaveTeamSFX } = useSoundEffect('leaveTeam');
  const audienceRTCService = useRTCService('audience');
  const retryCount = useRef(0);

  useEffect(() => {
    if (prevNumOfMembers === undefined) return;
    if (prevNumOfMembers !== numOfMembers) {
      if (prevNumOfMembers < numOfMembers) {
        joinTeamSFX();
      } else {
        leaveTeamSFX();
      }
    }
  }, [prevNumOfMembers, numOfMembers, joinTeamSFX, leaveTeamSFX]);

  const onRetry = async () => {
    if (retryCount.current >= 2) {
      safeWindowReload();
    } else {
      retryCount.current++;
      joinTeam({
        teamId,
        memberId: me.clientId,
        force: true,
        debug: 'join-team-fix',
      });
    }
  };

  if (!team) return null;

  return (
    <div className='flex flex-col group items-center pointer-events-off relative'>
      <LayoutAnchor id='team-stream-anchor' className='w-full' />
      {venueMode === VenueMode.Game && (
        <Header me={me} team={team} isLeader={isLeader} miniMode={miniMode} />
      )}
      {venueMode === VenueMode.Lobby && <LobbyBroadcastBundle />}
      {audienceRTCService && teamId && (
        <TeamView
          teamId={teamId}
          members={participants}
          rtcService={audienceRTCService}
          joinTaskQueue={taskQueue}
          miniMode={miniMode}
        />
      )}
      {!amIInTheTeam && <RetryJoinTeam onRetry={onRetry} />}
    </div>
  );
}

export function ClassicTeamView(props: {
  miniMode: boolean;
  participant: Participant | null;
  className: string;
  taskQueue: TaskQueue;
}): JSX.Element | null {
  const { participant } = props;
  const venueMode = useVenueMode();
  const teamId = participant?.teamId;

  if (!participant || !teamId) return null;

  // Safari has bugs when using z-index and transforms, so we attempt to match
  // the z transform to the z-index. Without this, the PlayerView will appear
  // behind the 3d-transformed CrowdView, even with different z-indices.
  const layeringClasses = `z-5 transform3d translate-x-0 translate-y-0 ${
    props.miniMode ? '' : 'translate-z-20'
  }`;

  return (
    <div
      className={`
        w-full h-auto
      text-white
        flex flex-col justify-center items-center flex-shrink-0
        relative
        transition-size duration-700
        ${layeringClasses}
        ${props.className}`}
    >
      {venueMode === VenueMode.Game && (
        <div
          className={`transition-transform duration-700 transform ${
            props.miniMode ? 'translate-y-12' : 'translate-y-0'
          } pb-2.5 pointer-events-off`}
        >
          <BroadcastIndicator isLobbyOrHost={false} />
        </div>
      )}

      <Container
        teamId={teamId}
        me={participant}
        miniMode={props.miniMode}
        venueMode={venueMode}
        taskQueue={props.taskQueue}
      />
    </div>
  );
}
