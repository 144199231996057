import { useEffect } from 'react';

import { unlockAudioContext } from '../services/audio/audio-context';
import { getFeatureQueryParam } from './useFeatureQueryParam';

const unlockAudioEnabled = getFeatureQueryParam('ensure-unlocked-audio');

export function useEnsureUnlockedAudioContext(): void {
  useEffect(() => {
    if (!unlockAudioEnabled) return;
    const handler = async () => {
      if (!(await unlockAudioContext())) {
        document.body.addEventListener('pointermove', handler, { once: true });
        document.body.addEventListener('pointerdown', handler, { once: true });
      }
    };

    document.body.addEventListener('pointermove', handler, { once: true });
    document.body.addEventListener('pointerdown', handler, { once: true });
  }, []);
}
