import { useEffect, useRef, useState } from 'react';

import { useAnalytics } from '../../analytics/AnalyticsContext';
import {
  DefaultLanguageOption,
  findLanguageOptionOrDefault,
  type LanguageOption,
  type SupportedLanguages,
} from '../../i18n/language-options';
import { apiService } from '../../services/api-service';
import { BrowserTimeoutCtrl } from '../../utils/BrowserTimeoutCtrl';
import { assertExhaustive } from '../../utils/common';
import { StorageStore } from '../../utils/storage';
import { useOndGameState, useReceivedIsLiveGamePlay } from '../Game/hooks';
import {
  ClosedCaptionsDisabledIcon,
  ClosedCaptionsEnabledIcon,
} from '../icons/ClosedCaptionsIcon';
import { SettingIcon } from '../icons/SettingIcon';
import { useI18nSettings } from '../Settings/useI18nSettings';
import { useGlobalSubtitlesManager } from '../VoiceOver/SubtitlesManagerProvider';
import {
  ClosedCaptionsAnalytics,
  useGetSharedTrackingProps,
} from './ClosedCaptionsAnalytics';

export function ClosedCaptionsControl(props: {
  className: string;
  openSettingsModal: Nullable<() => void>;
}) {
  const { i18nSettings, update } = useI18nSettings();
  const currentSubtitlesLocale = findLanguageOptionOrDefault(
    i18nSettings?.value?.subtitlesLocale
  );

  useEffect(() => {
    // Migrate the localStorage value to the backend if it has been selected in
    // the past and there is currently no value stored in the backend.

    // NOTE(drew:2024/08/22) This code can be deleted after a month or so of
    // being live.

    const storage = new StorageStore<{
      enabled: boolean;
      code: LanguageOption;
    }>('closedCaptions');

    const legacySubtitlesLocale = storage.get('code');
    const legacySubtitlesEnabled = storage.get('enabled');

    if (legacySubtitlesLocale?.value && !i18nSettings?.value) {
      update({
        subtitlesLocale: legacySubtitlesLocale.value,
        voiceOverLocale: legacySubtitlesLocale.value,
        subtitles: legacySubtitlesEnabled ?? false,
      });
    }
  }, [i18nSettings?.value, update]);

  const analytics = useAnalytics();
  const [ccAnalytics] = useState(() => new ClosedCaptionsAnalytics(analytics));
  const getAnalyticsExtra = useGetSharedTrackingProps();
  const isLiveGame = useReceivedIsLiveGamePlay();

  const handleToggle = () => {
    const next = !i18nSettings?.value?.subtitles;
    update({ subtitles: next });
    ccAnalytics.trackClosedCaptionsClicked(next, getAnalyticsExtra());
  };

  return isLiveGame ? null : (
    <div className={`${props.className} group`}>
      <button
        type='button'
        className={`icon-btn relative flex flex-col justify-center`}
        onClick={handleToggle}
      >
        {i18nSettings?.value?.subtitles ? (
          <ClosedCaptionsEnabledIcon />
        ) : (
          <ClosedCaptionsDisabledIcon />
        )}
      </button>
      <SubtitlesArea
        currentLanguage={currentSubtitlesLocale}
        className={`absolute left-full bottom-0 px-4.5 pointer-events-off ${
          // Always put this in the DOM so that the subtitles are immediately
          // visible if requested mid-sentence.
          i18nSettings?.value?.subtitles ? 'visible' : 'invisible'
        }`}
      />

      <button
        type='button'
        className='border border-secondary icon-btn absolute w-auto h-auto -top-2 -right-2 text-white hover:bg-gray-600 active:bg-secondary p-0.75'
        onClick={() => {
          props.openSettingsModal?.();
          ccAnalytics.trackClosedCaptionsSettingsClicked(getAnalyticsExtra());
        }}
      >
        <SettingIcon className='w-3 h-3 fill-current' />
      </button>
    </div>
  );
}

function SubtitlesArea(props: {
  className: string;
  currentLanguage: { label: string; value: string };
}) {
  // We use the types just to make the utilities easier to write / maintain, but
  // the incoming value is unchecked.
  const currentLanguageCode = props.currentLanguage.value as SupportedLanguages;

  const subman = useGlobalSubtitlesManager();
  const ref = useRef<null | HTMLDivElement>(null);
  const [printer] = useState(
    () =>
      new SubtitlesPrinter(
        ref,
        configureLineLength(currentLanguageCode),
        configureExpireDurationMs(currentLanguageCode),
        configureLineCount(currentLanguageCode)
      )
  );

  useEffect(() => {
    return subman.on('script-now', async (line) => {
      if (DefaultLanguageOption.value === currentLanguageCode) {
        printer.accept(line, currentLanguageCode);
      } else {
        const translated = await apiService.translation.translate({
          text: line,
          sourceLanguageCode: DefaultLanguageOption.value,
          targetLanguageCode: currentLanguageCode,
        });
        printer.accept(
          translated.data.text,
          translated.data.targetLanguageCode
        );
      }
    });
  }, [printer, currentLanguageCode, subman]);

  const previousLanguageRef = useRef(props.currentLanguage);
  useEffect(() => {
    if (currentLanguageCode !== previousLanguageRef.current.value) {
      printer.clear();
      printer.updateMaxLineLength(configureLineLength(currentLanguageCode));
      printer.accept(
        '[ updating translation. . . ]',
        DefaultLanguageOption.value
      );
      previousLanguageRef.current = props.currentLanguage;
    }
  }, [currentLanguageCode, printer, props.currentLanguage]);

  const ondGameState = useOndGameState();

  useEffect(() => {
    switch (ondGameState) {
      case 'paused':
        printer.pause();
        break;

      case 'running':
        printer.resume();
        break;

      case 'ended':
        printer.clear();
        break;

      case null:
      case 'preparing':
      case 'resuming':
        break;

      default:
        assertExhaustive(ondGameState);
        break;
    }
  }, [ondGameState, printer]);

  useEffect(() => {
    return () => {
      printer.destroy();
    };
  }, [printer]);

  return (
    <div className={`${props.className}`} ref={ref}>
      {/* Subtitles will be printed here */}
    </div>
  );
}

class CaptionChunkMetric {
  constructor(
    public word: string,
    public widthChars: number,
    public timeMs: number,
    public chunkSeparator = '\u00A0' // non-breaking space
  ) {}

  toDOM() {
    const $el = document.createElement('span');
    $el.textContent = this.word + this.chunkSeparator;
    return $el;
  }
}

class SubtitleLine {
  private open = true;
  private expireCtrl = new BrowserTimeoutCtrl();
  private widthChars = 0;
  readonly $el;

  constructor(
    private maxCharsPerLine: number,
    private expireDurationMs: number
  ) {
    this.$el = document.createElement('div');
    this.$el.classList.add(
      'inline-block',
      'bg-secondary',
      'text-white',
      'text-xl',
      'px-2',
      'whitespace-pre'
    );
    this.resetExpireTimer();
  }

  private resetExpireTimer() {
    this.expireCtrl.clear();
    this.expireCtrl.set(() => {
      this.exit();
    }, this.expireDurationMs);
  }

  private canAccept(word: CaptionChunkMetric) {
    return (
      this.open && this.widthChars + word.widthChars <= this.maxCharsPerLine
    );
  }

  accept(word: CaptionChunkMetric, force?: true) {
    if (!this.canAccept(word) && !force) return false;
    this.resetExpireTimer();
    this.widthChars += word.widthChars;
    this.$el.append(word.toDOM());
    return true;
  }

  exit() {
    this.expireCtrl.clear();
    // Mark this line as unable to accept more words, even if it has room!
    this.open = false;
    // remove from DOM
    this.$el.remove();
  }
}

class SubtitlesPrinter {
  private metrics: CaptionChunkMetric[] = [];
  private lines: SubtitleLine[] = [];

  private ctrl = new BrowserTimeoutCtrl();
  private waiting = false;

  private $el = document.createElement('div');

  constructor(
    private ref: { current: HTMLDivElement | null },
    private maxCharsPerLine: number,
    private expireDurationMs: number,
    private maxLines: number
  ) {}

  updateMaxLineLength(max: number) {
    this.maxCharsPerLine = max;
  }

  accept(text: string, lang: string) {
    const mcreator = createMetricsCreator(lang as SupportedLanguages);
    const metrics = mcreator.parse(text, lang);

    // enqueue them to be emitted into lines
    this.metrics.push(...metrics);
    this.kick();
  }

  pause() {
    this.ctrl.clear();
  }

  resume() {
    this.kick();
  }

  private kick() {
    if (this.waiting) return;

    const waitUntil = this.emitWord();
    if (waitUntil)
      this.ctrl.set(() => {
        this.waiting = false;
        this.kick();
      }, waitUntil);
  }

  private emitWord(): number {
    const word = this.metrics.shift();

    if (!word) return 0;

    // housekeeping: have we added the container to the DOM yet? We need
    // `.current` to be known first.
    if (!this.$el.parentElement) this.ref.current?.appendChild(this.$el);

    // find a line to accept the word
    const accepted = this.lines.at(-1)?.accept(word);

    if (!accepted) {
      // line is full, exit old lines, create new line

      const line = new SubtitleLine(
        this.maxCharsPerLine,
        this.expireDurationMs
      );
      this.lines.push(line);
      this.$el.appendChild(line.$el);
      // Force adding to the new line, even if it's too long.
      line.accept(word, true);

      // remove old lines
      while (this.lines.length > this.maxLines) {
        const line = this.lines.shift();
        if (line) line.exit();
      }
    }

    return word.timeMs;
  }

  clear() {
    this.lines.forEach((line) => line.exit());
    this.lines.length = 0;
    this.metrics.length = 0;
    this.ctrl.clear();
    this.waiting = false;
  }

  destroy() {
    this.clear();
    this.$el.remove();
  }
}

function cleanUpText(text: string) {
  return (
    text
      // remove drama dots
      .replace(/\.{3}/g, '')
      // Remove <break time="500ms"> tags
      .replace(/<break [^>]+>/g, '')
      // Replace newlines
      .replace(/\n|\r/g, ' ')
      // condense whitespace
      .replace(/ +/, ' ')
  );
}

interface MetricsCreator {
  parse(text: string, lang: string): CaptionChunkMetric[];
}

export class ASCIIMetricsCreator implements MetricsCreator {
  constructor(private charTimeMs: number) {}

  private timeText(word: string, _lang: string) {
    // NOTE: these are reasonable ms values for Finn v1.

    let timeMs = word.length * this.charTimeMs;
    const last = word[word.length - 1];

    if (last === '.' || last === '?' || last === '!' || last === ':') {
      timeMs += 1000;
    }

    if (last === ',') {
      timeMs += 300;
    }

    return timeMs;
  }

  private measureText(word: string, _lang: string) {
    // For now just keep in chars. Later we might try actual pixels, but this
    // gets quite expensive
    return word.length;
  }

  parse(text: string, lang: string) {
    const sanitized = cleanUpText(text);
    // Split into words and remove empties.
    const words = sanitized.split(/\s+/).filter((w) => w.length > 0);

    const metrics = words.map(
      (word) =>
        new CaptionChunkMetric(
          word,
          this.measureText(word + ' ', lang),
          this.timeText(word, lang),
          // When translations were returned from gcloud, korean always used two
          // spaces between the words :shrug:.
          lang === 'ko' ? '\u00A0\u00A0' : '\u00A0'
        )
    );
    return metrics;
  }
}

export class CJKMetricsCreator implements MetricsCreator {
  constructor(private characterTimeMs: number) {}

  parse(text: string, _lang: string) {
    const sanitized = cleanUpText(text);

    const metrics = [];
    const points = Array.from(sanitized);

    // https://en.wikipedia.org/wiki/CJK_Symbols_and_Punctuation
    // IDEOGRAPHIC SPACE, IDEOGRAPHIC COMMA, IDEOGRAPHIC FULL STOP,
    const cjkBreaks = /\u3000|\u3001|\u3002/u;
    const fullWidthPunc = /，|．|：|；|！|？/u;
    const cjkOpening = /「|『|（|【|〔|〈|《|〖|｛|〘|〚/u;
    const latinPunc = /,|\.|:|;|!|\?/u;
    const whitespace = /\s+/u;

    let currChunk = '';

    while (points.length > 0) {
      const curr = points.shift();
      if (curr === undefined) continue;
      else if (whitespace.exec(curr)) {
        if (currChunk.length > 0) {
          currChunk += curr;
        } else {
          // squelch initial/leading whitespace
          continue;
        }
      } else if (cjkBreaks.exec(curr)) {
        currChunk += curr;
        metrics.push(
          new CaptionChunkMetric(
            currChunk,
            currChunk.length,
            currChunk.length * this.characterTimeMs
          )
        );
        currChunk = '';
      } else if (cjkOpening.exec(curr)) {
        metrics.push(
          new CaptionChunkMetric(
            currChunk,
            currChunk.length,
            currChunk.length * this.characterTimeMs
          )
        );
        currChunk = '';
        currChunk += curr;
      } else if (fullWidthPunc.exec(curr)) {
        currChunk += curr;
        metrics.push(
          new CaptionChunkMetric(
            currChunk,
            currChunk.length,
            currChunk.length * this.characterTimeMs
          )
        );
        currChunk = '';
      } else if (latinPunc.exec(curr)) {
        currChunk += curr;
        metrics.push(
          new CaptionChunkMetric(
            currChunk,
            currChunk.length,
            currChunk.length * this.characterTimeMs
          )
        );
        currChunk = '';
      } else {
        currChunk += curr;
      }
    }

    // Close anything pending!
    if (currChunk.length > 0) {
      metrics.push(
        new CaptionChunkMetric(
          currChunk,
          currChunk.length,
          currChunk.length * this.characterTimeMs
        )
      );
    }

    // Slight parser hack: rather than checking when opening/closing a chunk,
    // just filter out empties here.
    const filtered = metrics.filter((m) => m.timeMs > 0 && m.widthChars > 0);

    return filtered;
  }
}

export function createMetricsCreator(lang: SupportedLanguages) {
  switch (lang) {
    case 'zh-CN':
    case 'zh-TW':
      return new CJKMetricsCreator(200);
    case 'ja':
      return new CJKMetricsCreator(125);
    case 'ko':
      // NOTE: not a bug that we use the ASCII metrics creator for Korean. It is
      // more like a Latin language than CJK in terms of punctuation and word
      // breaks.
      return new ASCIIMetricsCreator(100);
    default:
      return new ASCIIMetricsCreator(50);
  }
}

function configureLineLength(lang: SupportedLanguages) {
  switch (lang) {
    case 'zh-CN':
    case 'zh-TW':
    case 'ja':
      return 25;
    case 'ko':
    default:
      return 50;
  }
}

function configureExpireDurationMs(lang: SupportedLanguages) {
  switch (lang) {
    case 'zh-CN':
    case 'zh-TW':
    case 'ja':
      return 8000;
    case 'ko':
    default:
      return 5000;
  }
}

function configureLineCount(lang: SupportedLanguages) {
  switch (lang) {
    case 'zh-CN':
    case 'zh-TW':
    case 'ja':
      return 3;
    case 'ko':
    default:
      return 2;
  }
}
