import { useEffect, useReducer, useRef, useState } from 'react';

import { useLiveCallback } from '../../hooks/useLiveCallback';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import { type IMediaDeviceRTCService } from '../../services/webrtc';
import { ClientTypeUtils } from '../../types/user';
import { BrowserTimeoutCtrl } from '../../utils/BrowserTimeoutCtrl';
import { CohostSplitMenuControl } from '../Cohost/CohostMenu';
import { TertiaryTooltipBackground } from '../common/Utilities';
import { useBlockedPrompt, useDeviceAPI, useDeviceState } from '../Device';
import { AlertIcon } from '../icons/AlertIcon';
import { ArrowUpIcon } from '../icons/Arrows';
import { CameraIcon } from '../icons/CameraIcon';
import { CameraOffIcon } from '../icons/CameraOffIcon';
import { MicrophoneOffIcon } from '../icons/MicrophoneOffIcon';
import { SettingIcon } from '../icons/SettingIcon';
import { SpeakerIcon } from '../icons/SpeakerIcon';
import {
  LiteModeIcon,
  useLiteModeDisablesCamera,
  useLiteModeEnabled,
} from '../LiteMode';
import { useMyInstance, useParticipantFlags } from '../Player';
import { MenuKey } from '../Settings/types';
import { SpectatorCtrlButton } from '../Spectator';
import { useTownhallConfig } from '../Townhall';
import {
  useUserContext,
  useUserContextAPI,
  useUserStates,
} from '../UserContext';
import {
  useGameTeamVolumeBalancerValue,
  useMyClientType,
} from '../Venue/VenuePlaygroundProvider';
import {
  useMaybeCameraVideoMixer,
  useRTCService,
  useWebRTCMVMProcessor,
} from '../WebRTC';
import { AudioSelection } from './AudioSelection';
import { ClosedCaptionsControl } from './ClosedCaptionsControl';
import { DebugToolsButton } from './DebugToolsPanel';
import { IntercomControl } from './IntercomControl';
import { MicrophoneWithVolumeBar } from './MicrophoneWithVolumeBar';
import { VideoSelection } from './VideoSelection';
import { VolumeBalancerContainer } from './VolumeBalancer';

function BalancerTooltip(props: {
  headerText: string;
  bodyText?: string;
  className?: string;
}) {
  return (
    <div className={`pointer-events-off relative ${props.className ?? ''}`}>
      <div className='absolute w-75 left-5 bottom-0'>
        <div className='w-full h-full'>
          <TertiaryTooltipBackground className={`w-full h-full`} />
        </div>
        <div
          className={`w-full h-full
          p-3
          rounded-xl flex flex-col items-center justify-center
        text-black text-center absolute inset-0 -top-px`}
        >
          <div className='font-bold'>{props.headerText}</div>
          {props.bodyText && <div>{props.bodyText}</div>}
        </div>
      </div>
    </div>
  );
}

function useDelayedTooltipController(hasTeam: boolean, isHost: boolean) {
  const [showTooltip, setShowTooltip] = useState(false);

  const controlRef = useRef<{
    cancel: () => void;
    show: (show: boolean) => void;
  } | null>(null);

  useEffect(() => {
    if (isHost || !hasTeam || controlRef.current) return;

    let canceled = false;

    function cancel() {
      canceled = true;
    }

    function show(show: boolean) {
      setShowTooltip(show);
      if (show === false) cancel();
    }

    controlRef.current = {
      show,
      cancel,
    };

    function* Cancelable() {
      // Wait X seconds before showing the tooltip
      yield new Promise<void>((resolve) => setTimeout(resolve, 5000));

      show(true);

      // Show tooltip for only 20s.
      yield new Promise<void>((resolve) => setTimeout(resolve, 20000));

      show(false);
    }

    async function runner() {
      const c = Cancelable();
      while (true) {
        const next = c.next();
        if (next.done) break;
        await next.value;
        if (canceled) break;
      }
    }

    runner();

    return () => {
      cancel();
      controlRef.current = null;
    };
  }, [isHost, hasTeam]);

  return [showTooltip, controlRef.current] as const;
}

function useReturnToInitialAfterTimeoutState(
  initial = false,
  returnToInitialAfterMs = 20000
) {
  const [showTooltip, setShowTooltip] = useState(initial);

  useEffect(() => {
    let ref: ReturnType<typeof setTimeout> | null = null;

    if (showTooltip) {
      ref = setTimeout(
        () => setShowTooltip((v) => (v ? false : v)),
        returnToInitialAfterMs
      );
    }

    return () => {
      ref && clearTimeout(ref);
    };
  }, [returnToInitialAfterMs, showTooltip]);

  return [showTooltip, setShowTooltip] as const;
}

function useVideoState(rtcService: IMediaDeviceRTCService, isHost: boolean) {
  const userCtx = useUserContext();
  const { video } = useUserStates();
  const cameraVideoMixer = useMaybeCameraVideoMixer(rtcService);
  const mixMode = useDeviceAPI().mixer.mixMode;

  // Note(Jialin): the logic here is really complex and hard to understand.
  // We should finally migrate the _cameraVideoMixer_ to the new interface
  // and manage the source track in the mixer rather than in Agora.
  // Once that is done, we can simplify this logic by just checking _mixMode_.

  // - null: use the UserStates `video` field as the source of truth for
  //   camera/video state.
  // - boolean: the camera mixer (greenscreen effects) are enabled, the user
  //   will now _always_ have a stream (video: true), but manage camera state
  //   locally in this component so the user can still remove their camera
  //   stream from the mixer.
  const [derivedVideoState, setDerivedVideoState] = useState<null | boolean>(
    null
  );

  useEffect(() => {
    // _cameraVideoMixer_ is only used for host so far, we don't want the logic
    // to run for non-hosts.
    if (!isHost) return;
    // Handle the reactive case where the user switches their camera or turns an
    // effect on/off. Ensure the Camera Control button remains synced.
    return rtcService.on('video-switched', async () => {
      if (!cameraVideoMixer && derivedVideoState !== null) {
        // Special Case / Cleanup: The camera mixer was previously used since
        // the last camera toggle, but is no longer. Ensure the rtcService video
        // state is immediately synchronized, and reset tracking so that remote
        // UserState dictates camera state again.
        rtcService.toggleVideo(derivedVideoState);
        userCtx.toggleVideo(derivedVideoState);
        setDerivedVideoState(null);
      } else if (cameraVideoMixer && !video) {
        // Special Case: if the camera was muted, and the mixer was enabled
        // since the last toggle, mark the user's remote camera state as on,
        // toggle the video on (to ensure everything is synchronized), then turn
        // the camera off within the CameraVideoMixer's stream.
        userCtx.toggleVideo(true);
        await rtcService.toggleVideo(true);
        await rtcService.toggleVideo(false);
        setDerivedVideoState(false);
      }
    });
  }, [derivedVideoState, cameraVideoMixer, isHost, rtcService, userCtx, video]);

  const toggleVideo = (val: boolean) => {
    if (cameraVideoMixer) {
      // [Host]: Camera Mixer case: Track the state locally, and manually
      // manage toggling the camera stream.
      setDerivedVideoState(val);
      rtcService.toggleVideo(val);
      userCtx.toggleVideo(true, val);
    } else if (mixMode === 'full') {
      // [Cohost]: similar case,the IVideoEffectsMixer also has multiple tracks,
      // here we only toggle the camera track, but keep the mixed track
      // (with stage) on. The difference is that we don't need to control
      // _rtcService_ here since the _input_ track is fully managed by the mixer.
      setDerivedVideoState(val);
      userCtx.toggleVideo(true, val);
    } else {
      // [Player]: Normal, majority of users case: video state is directly tied
      // to device state.
      userCtx.toggleVideo(val);
    }
  };

  const localVideoEnabled =
    derivedVideoState === null ? video : derivedVideoState;

  return { video: localVideoEnabled, toggleVideo };
}

function useAudioState() {
  const { audio, micOpen, audioMutedBy } = useUserStates();
  // This will not affect most users. But the Host client uses the audioBus's
  // mic input to be broadcast intro/outro audio through the host agora channel
  // without the host's mic audio. This hook allows the visual state of the
  // button to remain synced during these moments of automatic control.
  return { audio: audio && micOpen, disabled: !micOpen, audioMutedBy };
}

type MediaControlNames = 'volume' | 'audio-select' | 'video-select';

function useShowMutedByHostWarning() {
  const [showHostMuteWarning, setShowHostMuteWarning] = useState(false);
  const showHostMuteWarningTimeout = useRef<BrowserTimeoutCtrl>(
    new BrowserTimeoutCtrl()
  );

  const userCtxAPI = useUserContextAPI();
  const cancelTimeout = useLiveCallback(() => {
    showHostMuteWarningTimeout.current.clear();
  });

  useEffect(() => {
    return userCtxAPI.on('audio-toggled', (prev, curr, mutedBy) => {
      if (prev === true && curr === false && mutedBy === 'host') {
        cancelTimeout();
        setShowHostMuteWarning(true);

        showHostMuteWarningTimeout.current.set(() => {
          setShowHostMuteWarning(false);
          cancelTimeout();
        }, 5000);
      } else if (curr === true) {
        setShowHostMuteWarning(false);
        cancelTimeout();
      }
    });
  }, [cancelTimeout, userCtxAPI]);

  useEffect(() => {
    return () => {
      cancelTimeout();
    };
  }, [cancelTimeout]);
  return showHostMuteWarning;
}

const MediaControls = (props: {
  showTeamInVolumeBalancer?: boolean;
  cohostSplitMenuEnabled?: boolean;
  openSettingsModal?: (key?: MenuKey | boolean) => void;
}): JSX.Element | null => {
  const {
    audioInputOptions,
    audioOutputOptions,
    videoInputOptions,
    audioSupported,
    videoSupported,
  } = useDeviceState();
  const ref = useRef<HTMLDivElement | null>(null);
  const rtcService = useRTCService('stage');
  const {
    audio,
    disabled: audioControlDisabled,
    audioMutedBy,
  } = useAudioState();
  const userCtx = useUserContext();
  const me = useMyInstance();
  const flags = useParticipantFlags(me?.clientId);
  const [, , setBlockedPromptOpen] = useBlockedPrompt();
  const [gameTeamVolumeBalance] = useGameTeamVolumeBalancerValue();
  const isHost = ClientTypeUtils.isHost(useMyClientType());
  const { video, toggleVideo } = useVideoState(rtcService, isHost);
  const liteMode = useLiteModeEnabled();
  const townahllConfig = useTownhallConfig();

  const [showHostVolumeAtZeroTooltip, setShowHostVolumeAtZeroTooltip] =
    useReturnToInitialAfterTimeoutState();

  const [showVolumeTutorialTooltip, volumeTutorialTooltipCtl] =
    useDelayedTooltipController(!!me?.teamId, isHost);

  const [mediaControlsOpen, toggleMediaControls] = useReducer(
    (state: null | MediaControlNames, action: null | MediaControlNames) => {
      if (state === action) return null;
      else return action;
    },
    null
  );

  const volumeButtonIsRed =
    gameTeamVolumeBalance === 0 && mediaControlsOpen === null;

  useEffect(() => {
    if (isHost) return;
    if (volumeButtonIsRed) setShowHostVolumeAtZeroTooltip(true);
    else setShowHostVolumeAtZeroTooltip(false);
  }, [isHost, setShowHostVolumeAtZeroTooltip, volumeButtonIsRed]);

  useEffect(() => {
    if (isHost) return;
    if (mediaControlsOpen !== null) {
      volumeTutorialTooltipCtl?.show(false);
    }
  }, [mediaControlsOpen, volumeTutorialTooltipCtl, isHost]);

  const handleCloseAny = () => {
    toggleMediaControls(null);
  };

  const handleToggleVideo = (val: boolean) => {
    if (!flags?.hasCamera) {
      setBlockedPromptOpen(true);
      return;
    }

    toggleVideo(val);
  };

  const handleToggleAudio = (val: boolean) => {
    if (!flags?.hasMicrophone) {
      setBlockedPromptOpen(true);
      return;
    }
    userCtx.toggleAudio(val);
  };

  useLiteModeDisablesCamera();

  useOutsideClick(ref, () => handleCloseAny());
  const micVolumeMeterProcessor = useWebRTCMVMProcessor();
  const showMutedByHostWarning = useShowMutedByHostWarning();

  if (!me) return null;

  const showMuteWarning =
    audio === false &&
    audioMutedBy === 'system' &&
    townahllConfig.mode === 'crowd';

  return (
    <div ref={ref} className='absolute bottom-0 left-0 m-2 z-45'>
      <div className='flex flex-col justify-between relative'>
        {/* simply disable the tooltip w/o change code logic */}
        {showVolumeTutorialTooltip && false && (
          <BalancerTooltip
            className='w-10 h-10'
            headerText='Adjust the volumes of the Host and the background music here!'
          />
        )}

        {showHostVolumeAtZeroTooltip && (
          <BalancerTooltip
            className='w-10 h-10'
            headerText='The game volume is set to 0!'
            bodyText="You won't be able to hear the Host or the game's media."
          />
        )}

        <SpectatorCtrlButton />

        {props.cohostSplitMenuEnabled && (
          <CohostSplitMenuControl className='relative my-1.5' />
        )}

        <DebugToolsButton />

        <div className='relative my-1.5'>
          <button
            type='button'
            className={`icon-btn relative ${
              volumeButtonIsRed ? 'bg-lp-red-001 hover:bg-lp-red-001' : ''
            }`}
            onClick={() => toggleMediaControls('volume')}
          >
            <SpeakerIcon className='w-4.5 h-4.5 fill-current' />
          </button>
          <VolumeBalancerContainer
            isOpen={mediaControlsOpen === 'volume'}
            showTeam={props.showTeamInVolumeBalancer}
          />
        </div>

        {audioSupported && (
          <div className='relative my-1.5'>
            <div
              className={`
                bg-warning h-10 absolute rounded-xl top-0 left-0 
                flex items-center justify-start gap-3 text-sms font-bold 
                pointer-events-none transition-all duration-300 overflow-hidden
                ${
                  showMuteWarning
                    ? 'w-37.5 opacity-100'
                    : showMutedByHostWarning
                    ? 'w-72 opacity-100'
                    : 'w-10 opacity-0'
                }
              `}
            >
              <div className='flex-none w-10 h-10 rounded-xl bg-black bg-opacity-40' />
              <div
                className={`whitespace-nowrap flex-nowrap ${
                  showMuteWarning || showMutedByHostWarning
                    ? 'visible'
                    : 'invisible'
                }`}
              >
                {showMutedByHostWarning ? (
                  <>The host has muted all participants</>
                ) : showMuteWarning ? (
                  <>You are muted</>
                ) : null}
              </div>
            </div>
            <button
              type='button'
              className={`icon-btn relative ${
                !flags?.hasMicrophone && 'bg-lp-red-001 hover:bg-lp-red-001'
              }`}
              data-testid='toggle-audio'
              disabled={audioControlDisabled}
              onClick={() => handleToggleAudio(!audio)}
            >
              {audio ? (
                <MicrophoneWithVolumeBar processor={micVolumeMeterProcessor} />
              ) : (
                <MicrophoneOffIcon className='w-4.5 h-4.5 fill-current' />
              )}
            </button>
            {flags?.hasMicrophone ? (
              <button
                type='button'
                className='border border-secondary icon-btn absolute w-auto h-auto -top-2 -right-2 text-white hover:bg-gray-600 active:bg-secondary p-0.75'
                onClick={() => toggleMediaControls('audio-select')}
              >
                <ArrowUpIcon className='w-3 h-3 fill-current' />
              </button>
            ) : (
              <div className='absolute -top-1.5 -right-1.5'>
                <AlertIcon />
              </div>
            )}
            {mediaControlsOpen === 'audio-select' && (
              <AudioSelection
                handleClose={handleCloseAny}
                audioInput={{
                  enabled: true,
                  options: audioInputOptions,
                }}
                audioOutput={{
                  enabled: true,
                  options: audioOutputOptions,
                }}
              />
            )}
          </div>
        )}

        {videoSupported && (
          <div className='relative my-1.5'>
            <button
              type='button'
              className={`icon-btn ${
                !flags?.hasCamera && 'bg-lp-red-001 hover:bg-lp-red-001'
              }`}
              data-testid='toggle-video'
              onClick={() => handleToggleVideo(!video)}
              disabled={liteMode}
            >
              {video ? (
                <CameraIcon className='w-4.5 h-4.5 fill-current' />
              ) : (
                <CameraOffIcon className='w-4.5 h-4.5 fill-current' />
              )}
            </button>
            {flags?.hasMicrophone ? (
              <button
                type='button'
                className='border border-secondary icon-btn absolute w-auto h-auto -top-2 -right-2 text-white hover:bg-gray-600 active:bg-secondary p-0.75'
                onClick={() => toggleMediaControls('video-select')}
              >
                <ArrowUpIcon className='w-3 h-3 fill-current' />
              </button>
            ) : (
              <div className='absolute -top-1.5 -right-1.5'>
                <AlertIcon />
              </div>
            )}
            {mediaControlsOpen === 'video-select' && (
              <VideoSelection
                handleClose={handleCloseAny}
                videoInputOptions={videoInputOptions}
              />
            )}
          </div>
        )}

        <IntercomControl className='relative my-1.5' />

        <ClosedCaptionsControl
          className='relative my-1.5'
          openSettingsModal={() => props.openSettingsModal?.(MenuKey.Language)}
        />

        <div className='relative mt-1.5'>
          {liteMode ? (
            <div className='absolute -top-0.5 w-full pointer-events-off flex justify-center'>
              <LiteModeIcon />
            </div>
          ) : null}
          {props.openSettingsModal && (
            <button
              type='button'
              className='icon-btn'
              onClick={() => props.openSettingsModal?.(true)}
            >
              <SettingIcon className='w-4.5 h-4.5 fill-current' />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export { AudioSelection, MediaControls, MicrophoneWithVolumeBar };
